
export default class {
  static readOnlyTitle(text, params, htmlTag) {
    return {
      "view": "panels/horizontal",
      "width": "matchParent",
      "height": "matchParent",
      "align": "middle",
      "childViews": [
        {
          "view": htmlTag || "h4",
          "text": text,
          "width": "matchParent",
        }
      ],
      "onClick": {
        "action": "commands/custom",
        "name": "cards/edit",
        "properties": params
      }
    }
  }

  static readOnlyButton(text, params) {
    return {
      "view": "button",
      "text": text,
      "styleClasses": ["tonal", "small"],
      "onClick": {
        "action": "commands/custom",
        "name": "cards/edit",
        "properties": params
      }
    }
  }

  static editableTitle(value, label, onSubmit) {
    return {
      "view": "panels/form",
      "autoFocus": true,
      "width": "matchParent",
      "childViews": [
        {
          "view": "fields/text",
          "styleClasses": ["outlined", "compact"],
          "width": "matchParent",
          "name": "name",
          "value": value,
          "label": label
        },
        {
          "view": "panels/horizontal",
          "width": "matchParent",
          "height": "matchParent",
          "align": "middle",
          "childViews": [
            {
              "view": "fields/submit",
              "styleClasses": ["tonal", "small"],
              "text": "Save"
            },
            {
              "view": "spacer",
              "width": 6
            },
            {
              "view": "button",
              "styleClasses": $tpu.styles.iconButtonClasses(),
              "icon": { "material": { "name": $tpu.keys.icnCancel() } },
              "onClick": {
                "action": "commands/custom",
                "name": "cards/edit",
                "properties": {}
              }
            }
          ]
        }
      ],
      "onSubmit": onSubmit
    }
  }

  static editableText(fieldSpec, onSubmit) {
    return {
      "view": "panels/form",
      "autoFocus": true,
      "width": "matchParent",
      "childViews": [
        fieldSpec,
        {
          "view": "panels/horizontal",
          "width": "matchParent",
          "height": "matchParent",
          "align": "middle",
          "childViews": [
            {
              "view": "fields/submit",
              "styleClasses": ["tonal", "small"],
              "text": "Save"
            },
            {
              "view": "spacer",
              "width": 6
            },
            {
              "view": "button",
              "styleClasses": $tpu.styles.iconButtonClasses(),
              "icon": { "material": { "name": $tpu.keys.icnCancel() } },
              "onClick": {
                "action": "commands/custom",
                "name": "cards/edit",
                "properties": {}
              }
            }
          ]
        }
      ],
      "onSubmit": onSubmit
    }
  }

  static readOnlyText(spec, params, focusId) {
    // return spec
    return {
      "view": "panels/vertical",
      "width": "matchParent",
      "childViews": [
        spec
      ],
      "onClick": {
        "action": "commands/custom",
        "name": "cards/edit",
        "properties": params,
        // "onSuccess": {
        //   "action": "fields/focus",
        //   "targetId": focusId,
        // }
      }
    }
  }
}